import React from "react";
import { ourValuesDetails, routePaths } from "../../utills/Constants";
import { Button } from "antd";
import { Arrow } from "../../assests/svg/Arrow";
import { useNavigate } from "react-router-dom";

function OurValues() {
  const navigate = useNavigate();

  return (
    <section className="py-16 sm:px-5 sm:bg-light_grey_1 rounded-2xl text-center">
      <p className="text-5xl text-dark_grey_1 font-extrabold py-4">
        Our values
      </p>
      <span className="text-sm text-light_grey_2">
        Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit <br />
        phasellus mollis sit aliquam sit nullam neque ultrices.
      </span>
      <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-flow-row gap-4 py-10 sm:px-5">
        {ourValuesDetails.map((item, idx) => (
          <div
            className="p-5 bg-white rounded-xl flex flex-col items-center drop-shadow-xl"
            key={`card_${idx}`}
          >
            <img src={item.picture} alt="valuesImage" />
            <p className="py-4 text-2xl font-bold">{item.title}</p>
            <p className="text-sm text-light_grey_2">{item.description}</p>
          </div>
        ))}
      </div>
      <Button
        className="mt-5 bg-appPrimary hover:!bg-appPrimary border-none h-12 sm:w-auto w-full"
        onClick={() => navigate(routePaths.career)}
      >
        <div className="text-appSecondary flex items-center justify-center gap-3 text-base font-semibold">
          Join Our Team <Arrow width="13" height="13" />
        </div>
      </Button>
    </section>
  );
}

export default OurValues;
