import ProfileImage from "../../assests/svg/customerProfile.svg";
import FounderImage from "../../assests/svg/CEOProfile.svg";
import PieChart from "../../assests/svg/pieChart.svg";
import Elipse from "../../assests/svg/elipse.svg";
import { tabData } from "../../utills/Constants";
import CustomTabs from "../common/tab/CustomTabs";
import { Button } from "antd";
import { Arrow } from "../../assests/svg/Arrow";

function Testimonials() {
  return (
    <section>
      <div className="w-full primary-bg text-center text-white pt-16 min-h-[22rem] relative">
        <p className="text-sm">Testimonials</p>
        <p className="sm:text-5xl text-4xl font-extrabold mb-10">
          What our happy customers say
        </p>
        <div className="md:w-[85%] md:mx-0 mx-4 bg-white md:flex absolute md:p-16 p-5 right-0 items-center justify-around drop-shadow-2xl">
          <div className="flex flex-col md:items-start items-center md:mb-0 mb-5">
            <img src={ProfileImage} alt="profile" className="mb-4" />
            <p className="text-2xl text-dark_grey_1 font-semibold mb-2">
              Jessica Ren
            </p>
            <p className="text-sm text-light_grey_3">
              Marketing Strategist at XYZ Enterprises
            </p>
          </div>
          <div className="md:w-[50%]">
            <CustomTabs tabData={tabData} />
          </div>
        </div>
      </div>
      <div className="lg:h-[13rem] md:h-[21rem] sm:h-[24rem] h-[30rem]"></div>
      <div className="lg:p-32 pt-32 md:px-16 sm:px-10 px-5 flex md:flex-row flex-col-reverse items-center justify-between">
        <div className="md:w-[60%] md:mt-0 mt-10">
          <p className="lg:text-5xl text-3xl text-appSecondary font-extrabold lg:leading-[4rem]">
            Passion to increase <br /> company revenue
          </p>
          <p className="text-sm text-light_grey_2 py-6 font-semibold">
            It's clear to us that each business has its own uniqueness, and
            there's no one universal solution.
          </p>
          <p className="text-sm text-light_grey_2 pb-6">
            "Achievement involves more than simply reaching the end goal; it
            entails embracing the entire voyage, deriving lessons from
            obstacles, and consistently adapting to maintain a competitive edge.
            At Consultant, we are dedicated to collaborating with our clients
            throughout their journey, directing them with our proficiency, and
            an unwavering drive for perfection."
          </p>
          <p className="text-dark_grey_1 sm:text-2xl text-lg font-bold">
            Paul Stephen
          </p>
          <span className="text-sm text-light_grey_3">
            Founder & CEO at GPA Foods & Intouch
          </span>
        </div>
        <img src={FounderImage} alt="FounderImage" className="md:w-[30%]" />
      </div>
      <div className="md:mx-16 mx-5 rounded-md primary-bg md:px-32 sm:px-16 px-5 py-16 text-white text-center relative -bottom-[12rem]">
        <p className="sm:text-5xl text-3xl font-extrabold mb-5 z-40">
          Your trusted source for end-to-end <br /> technology solutions
        </p>
        <p className="text-sm mb-8">
          In a technological world where change is the only constant, it takes
          vision, experience and a wide range of capabilities to help businesses
          overcome their technology challenges. That’s exactly what you get when
          you partner with Neudesic. From design to development to support to
          management, Neudesic offers decades of experience, proven frameworks
          and a disciplined approach to quickly deliver reliable, quality
          solutions that help you go to market faster and get a leg up on your
          competition.
        </p>
        <Button className="mt-5 bg-appPrimary hover:!bg-appPrimary border-none h-12 w-full sm:w-auto">
          <div className="text-appSecondary flex items-center justify-center gap-3">
            Enquire Now <Arrow width="13" height="13" />
          </div>
        </Button>
        <img
          src={PieChart}
          alt="piechart"
          className="absolute right-10 bottom-7 hidden sm:block"
        />
        <img
          src={Elipse}
          alt="elipse"
          className="absolute left-0 top-7 h-[10rem] rotate-180 hidden sm:block"
        />
      </div>
    </section>
  );
}

export default Testimonials;
