import React, { useState } from "react";
import { allIndustryDetails } from "../../utills/Constants";

function AllIndustries() {
  const [selectedTab, setSelectedTab] = useState(0);
  const tabdetails = allIndustryDetails[selectedTab];

  const handleTopicClicked = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <section className="py-10 lg:px-24 md:px-8 px-5 rounded-3xl bg-white text-center drop-shadow-md">
      <span className="text-sm text-appSecondary">Industries</span>
      <p className="md:text-5xl text-3xl font-extrabold mt-3">All Industries</p>
      <div className="sm:pt-16 pt-10 sm:flex">
        <div className="sm:w-[30%] text-start flex sm:flex-col overflow-y-auto no-scrollbar sm:pb-0 pb-6">
          {allIndustryDetails.map((item, idx) => (
            <div
              key={`tab_${idx}`}
              className={`w-full p-2 ps-4 cursor-pointer sm:min-w-0 min-w-[14rem] sm:text-start text-center ${
                selectedTab === idx &&
                "bg-appSecondary text-appPrimary rounded-tl-3xl rounded-br-3xl"
              }`}
              onClick={() => handleTopicClicked(idx)}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div className="sm:w-[70%] text-start sm:ps-5 flex flex-col justify-between h-full">
          <div className="h-[17rem] w-full">
            <img
              src={tabdetails.coverPhoto}
              alt="coverImage"
              className="w-full h-full object-cover rounded"
            />
          </div>
          <div className="min-h-[10.5rem]">
            {tabdetails.description ? (
              <>
                <p className="sm:text-3xl text-xl text-dark_grey_1 my-3 font-extrabold">
                  {tabdetails.title}
                </p>
                <span className="text-sm text-light_grey_2">
                  {tabdetails.subTopic}
                </span>
                <p className="text-sm my-3">{tabdetails.description}</p>
              </>
            ) : (
              "No data found"
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AllIndustries;
