import React from "react";
import CardImageOne from "../../assests/svg/conferenceImg.svg";
import CardImageTwo from "../../assests/svg/cardImg.svg";
import Arrow from "../../assests/svg/arrow.svg";
import Elipse from "../../assests/svg/elipse.svg";
import Stripe from "../../assests/svg/stripe.svg";
import { offeredServiceDetails, routePaths } from "../../utills/Constants";
import { useNavigate } from "react-router-dom";

function OfferedService() {
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <main className="w-full flex flex-col items-center relative">
      <section className="w-[90%] sm:p-12 px-5 py-12 rounded-3xl bg-light_grey_1 absolute md:bottom-[47rem] sm:bottom-[70rem] bottom-[75rem]">
        <section className="md:flex items-center justify-between sm:pb-5 ">
          <div className="md:pe-20">
            <p className="lg:text-4xl md:text-lg text-2xl md:font-semibold font-extrabold mb-5">
              We are your trusted technology partner in business innovation -
              delivering technology, strategies and solutions that drive
              business growth.
            </p>
            <p className="lg:text-lg md:text-base text-light_grey_2 leading-5 md:pb-0 pb-5">
              We are innovators, business advisors and passionate technologists
              collaborating in unison to ideate, create and streamline business
              workflows that enable sustained value for your organization
            </p>
          </div>
          <img
            className="grayscale lg:w-[25rem] lg:h-[25rem] md:w-[15rem] md:h-[15rem] w-full h-[20rem] sm:pb-0 pb-5"
            src={CardImageOne}
            alt="cardone"
          />
          <img
            src={Elipse}
            alt="elipse"
            className="lg:block hidden  absolute right-0  bottom-[14rem] opacity-75 "
          />
        </section>
        <section className="md:flex items-center justify-between ">
          <img
            src={Stripe}
            alt="stripe"
            className="absolute left-0 bottom-[-4px] hidden md:block"
          />
          <img
            src={CardImageTwo}
            alt="cardTwo"
            className="lg:w-[25rem] lg:h-[25rem] md:w-[15rem] md:h-[15rem] w-full h-[20rem] z-20 hidden md:block"
          />
          <div className="md:ps-20">
            <p className="lg:text-4xl md:text-lg text-2xl md:font-semibold font-extrabold mb-5">
              What we deliver
            </p>
            <p className="lg:text-lg md:text-base text-light_grey_2 leading-5 md:pb-0 pb-5 ">
              Pioneering thinking, transformative insights and tangible outcomes
              that accelerate your journey to the cloud, aligning technologies
              to your core business strategies – enabling long-term growth from
              your investments.
            </p>
          </div>
          <img
            src={CardImageTwo}
            alt="cardTwo"
            className="lg:w-[25rem] lg:h-[25rem] md:w-[15rem] md:h-[15rem] w-full h-[20rem] z-20 md:hidden block"
          />
        </section>
      </section>
      <div className="md:min-h-[36.5rem] min-h-[70rem]"></div>
      <section className="sm:px-16 px-5 py-28 w-full">
        <span className="text-sm text-appSecondary font-medium">Services</span>
        <p className="sm:text-5xl text-4xl font-extrabold">Services We Offer</p>
        <div className="flex items-center justify-between text-light_grey_2 text-sm">
          <p className="mt-4 sm:mb-0 mb-5">
            Discover more about our end-to-end capabilities and solutions
          </p>
          <p
            className="items-center sm:flex hidden cursor-pointer"
            onClick={() => handleNavigate(routePaths.service)}
          >
            View All Services <img src={Arrow} alt="arrow" className="ms-4" />
          </p>
        </div>
        <div className="sm:my-16 grid md:grid-cols-3 grid-flow-row gap-8">
          {offeredServiceDetails.map((item, idx) => {
            return (
              <div className="border-l-2 ps-4" key={`service_${idx}`}>
                <p className="text-2xl font-bold text-dark_grey_1 mb-4">
                  {item.title}
                </p>
                <span className="text-light_grey_2 text-sm">
                  {item.description}
                </span>
              </div>
            );
          })}
        </div>
        <div
          className="flex items-center justify-center sm:hidden text-center mt-16 cursor-pointer"
          onClick={() => handleNavigate(routePaths.service)}
        >
          View All Services <img src={Arrow} alt="arrow" className="ms-4" />
        </div>
      </section>
    </main>
  );
}

export default OfferedService;
