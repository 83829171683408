import React, { useEffect, useState } from "react";
import Logo from "../../../assests/svg/logo.svg";
import { Arrow } from "../../../assests/svg/Arrow";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import Hamburger from "../../../assests/svg/menu.svg";
import { routePaths } from "../../../utills/Constants";

const navs = [
  { label: "Home", path: routePaths.home },
  { label: "Services", path: routePaths.service },
  { label: "Industries", path: routePaths.industry },
  { label: "About", path: routePaths.about },
  { label: "Careers", path: routePaths.career },
];

function TopNavBar() {
  const [selectedNav, setSelectedNav] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const handleNavClick = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    setSelectedNav(window.location.pathname);
    setShowMenu(false);
  }, []);

  return (
    <>
      <nav className="h-[4rem] flex justify-between items-center primary-bg lg:px-16 md:px-10 sm:px-5 px-4 sticky top-0 z-[100]">
        <div>
          <img src={Logo} alt="logo" className="w-[10rem]" />
        </div>
        <div className="text-white sm:flex items-center hidden">
          {navs.map((navItems, idx) => {
            return (
              <div
                className={`lg:ms-5 cursor-pointer lg:text-base sm:text-sm sm:ms-3 ${
                  selectedNav === navItems.path && "text-appPrimary"
                }`}
                onClick={() => handleNavClick(navItems.path)}
                key={`navLink_${idx}`}
              >
                {navItems.label}
              </div>
            );
          })}
          <Button
            className="bg-appPrimary hover:!bg-appPrimary lg:ms-8 sm:ms-4 border-none"
            onClick={() => handleNavClick(routePaths.contact)}
          >
            <div className="flex items-center gap-2 text-appSecondary font-medium">
              Contact Us
              <Arrow width="13" height="13" />
            </div>
          </Button>
        </div>
        <img
          src={Hamburger}
          alt="menu"
          onClick={() => setShowMenu(!showMenu)}
          className="sm:hidden visible cursor-pointer"
        />
      </nav>
      {showMenu && (
        <div className="w-full bg-dark_grey_1 text-white sticky top-[4rem] z-50 sm:hidden visible">
          {navs.map((navItems, idx) => {
            return (
              <div
                className={`text-lg py-2 px-5 hover:bg-light_grey_3 cursor-pointer ${
                  selectedNav === navItems.path && "text-appPrimary"
                }`}
                onClick={() => handleNavClick(navItems.path)}
                key={`navLink_${idx}`}
              >
                {navItems.label}
              </div>
            );
          })}
          <div className="p-5">
            <Button
              className="bg-appPrimary hover:!bg-appPrimary border-none w-full"
              onClick={() => handleNavClick(routePaths.contact)}
            >
              <div className="flex items-center justify-center gap-2 text-appSecondary font-medium">
                Contact Us
                <Arrow width="13" height="13" />
              </div>
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default TopNavBar;
