import BGImageTwo from "../../assests/svg/workingIllustration2.svg";
import Facebook from "../../assests/svg/facebook.svg";
import Google from "../../assests/svg/google.svg";
import Pinterest from "../../assests/svg/pinterest.svg";
import Twitch from "../../assests/svg/twitch.svg";
import Webflow from "../../assests/svg/webflow.svg";
import Youtube from "../../assests/svg/youtube.svg";
import Elipse from "../../assests/svg/elipse.svg";
import YellowCircle from "../../assests/svg/fullCircle.svg";
import OurValues from "../../components/about/OurValues";
import { whySassGenie } from "../../utills/Constants";
import Offices from "../../components/about/Offices";
import Testimonials from "../../components/about/Testimonials";
import { useEffect } from "react";
import { scrollElement } from "../../utills/utill";

function About() {
  useEffect(() => {
    scrollElement("about");
  }, []);

  return (
    <main id="about">
      <header className="primary-bg md:px-16 px-5 pt-[8rem] text-white text-center flex flex-col items-center lg:h-screen md:h-[130vh] sm:h-[90vh] h-[90vh] !relative">
        <span className="text-sm text-appPrimary">About Us</span>
        <p className="md:text-5xl text-3xl font-extrabold py-10 md:leading-[4rem]">
          SassGenie’s goal is to create real <br /> business value for our
          clients.
        </p>
        <p className="text-sm md:w-[50%]">
          A preeminent provider of strategic marketing consulting services,
          driven by a fervent commitment to assisting businesses in unlocking
          their complete potential and attaining remarkable growth within the
          current dynamic and competitive marketplace.
        </p>
        <img
          src={BGImageTwo}
          alt="illustration"
          className="absolute lg:-bottom-[16.1rem] md:-bottom-[9.8rem] sm:-bottom-[8.1rem] -bottom-[4.7rem]"
        />
      </header>
      <section>
        <div className="lg:h-[17rem] md:h-[11rem] sm:h-[9rem] h-[6rem]"></div>
        <div className="md:flex md:p-16 p-5">
          <div className="md:w-1/2 md:pe-14">
            <span className="text-sm text-appSecondary">Why Choose Us?</span>
            <p className="sm:text-5xl text-3xl text-dark_grey_1 font-extrabold py-4">
              Why SassGenie?
            </p>
            <p className="text-sm leading-[2rem] text-light_grey_2 md:mb-0 mb-8">
              Passion for technology drives us. But it’s innovation that defines
              us. From design to development and support to management, Neudesic
              offers decades of experience, proven frameworks and a disciplined
              approach to quickly deliver reliable, quality solutions that help
              you go to market faster and get a leg up on your competition.
            </p>
          </div>
          <div className="md:w-1/2 md:ps-14">
            <ul className="list-disc text-light_grey_2 md:mb-0 mb-8 ms-4 md:ms-0 mt-8">
              {whySassGenie.map((reason, idx) => (
                <li key={`list_${idx}`} className="mb-4">
                  {reason}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <div className="md:px-16 px-5 pb-20">
        <OurValues />
      </div>
      <section className="primary-bg py-16 text-white text-center flex flex-col items-center relative md:px-0 px-5 ">
        <p className="md:text-5xl text-3xl font-extrabold mb-4 md:leading-[4rem]">
          We’re backed by the best <br /> brands out there
        </p>
        <span className="text-sm">
          Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
          <br />
          phasellus mollis sit aliquam sit nullam neque ultrices.
        </span>
        <div className="grid lg:grid-rows-1 sm:grid-rows-2 grid-rows-3 grid-flow-col gap-14 mt-10">
          <img src={Facebook} alt="facbookLogo" />
          <img src={Google} alt="googleLogo" />
          <img src={Pinterest} alt="pinterestLogo" />
          <img src={Twitch} alt="twitchLogo" />
          <img src={Webflow} alt="webflowLogo" />
          <img src={Youtube} alt="youtubeLogo" />
        </div>
        <img
          src={Elipse}
          alt="elipse"
          className="absolute right-0 h-[10rem] bottom-2 sm:block hidden"
        />
        <img
          src={YellowCircle}
          alt="elipse"
          className="absolute left-2 h-[10rem] top-2"
        />
      </section>
      <Offices />
      <Testimonials />
    </main>
  );
}

export default About;
