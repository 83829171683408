import React from "react";
import Layout from "../components/common/layout/Layout";
import Career from "../pages/career/Career";

function CareerContainer() {
  return (
    <Layout>
      <Career />
    </Layout>
  );
}

export default CareerContainer;
