import React from "react";
import Layout from "../components/common/layout/Layout";
import Industry from "../pages/industry/Industry";

function IndustryContainer() {
  return (
    <Layout footerExtendedHeight={true}>
      <Industry />
    </Layout>
  );
}

export default IndustryContainer;
