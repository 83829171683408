import React from "react";
import ProfileImage from "../../assests/svg/customerProfile.svg";
import Arrow from "../../assests/svg/arrow.svg";
import CustomTabs from "../common/tab/CustomTabs";
import { caseStudies, tabData } from "../../utills/Constants";
import CustomCard from "../common/card/CustomCard";

function CaseStudies() {
  return (
    <React.Fragment>
      <section className="w-full primary-bg text-center text-white pt-16 min-h-[22rem] relative">
        <p className="text-sm">Testimonials</p>
        <p className="sm:text-5xl text-4xl font-extrabold mb-10">
          What our happy customers say
        </p>
        <div className="md:w-[85%] md:mx-0 mx-4 bg-white md:flex absolute md:p-16 p-5 right-0 items-center justify-around drop-shadow-2xl">
          <div className="flex flex-col md:items-start items-center md:mb-0 mb-5">
            <img src={ProfileImage} alt="profile" className="mb-4" />
            <p className="text-2xl text-dark_grey_1 font-semibold mb-2">
              Jessica Ren
            </p>
            <p className="text-sm text-light_grey_3">
              Marketing Strategist at XYZ Enterprises
            </p>
          </div>
          <div className="md:w-[50%]">
            <CustomTabs tabData={tabData} />
          </div>
        </div>
      </section>
      <section>
        <div className="md:min-h-[18rem] min-h-[28rem]"></div>
        <div className=" pt-24 pb-32">
          <span className="text-appSecondary text-sm font-medium md:px-16 px-5">
            Case Studies
          </span>
          <p className="md:text-5xl text-4xl font-extrabold mb-5 md:px-16 px-5">
            Featured client success stories
          </p>
          <div className="flex items-center justify-between md:px-16 px-5">
            <p className="text-light_grey_2 text-sm font-medium">
              Discover more about our end-to-end capabilities and solutions
            </p>
            <p className="text-light_grey_2 text-sm font-medium md:flex items-center gap-3 hidden">
              View All Case Studies
              <img src={Arrow} alt="arrow" className="w-3 h-3" />
            </p>
          </div>
          <div className="mt-14 grid grid-rows-1 grid-flow-col gap-10 overflow-x-scroll no-scrollbar md:px-16 px-5 pb-16">
            {caseStudies.map((details, idx) => {
              return <CustomCard data={details} key={`case_${idx}`} />;
            })}
          </div>
          <p className="text-light_grey_2 text-sm font-medium flex items-center justify-center gap-3 md:hidden mt-10 md:px-16 px-5">
            View All Case Studies
            <img src={Arrow} alt="arrow" className="w-3 h-3" />
          </p>
        </div>
      </section>
    </React.Fragment>
  );
}

export default CaseStudies;
