import { Button } from "antd";
import { useEffect } from "react";
import { Arrow } from "../../assests/svg/Arrow";
import IndustryImage from "../../assests/svg/industriesRight.svg";
import Underline from "../../assests/svg/underline.svg";
import AllIndustries from "../../components/industry/AllIndustries";
import Elipse from "../../assests/svg/elipse_1.svg";
import PostBox from "../../assests/svg/postBox.svg";
import { scrollElement } from "../../utills/utill";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../utills/Constants";

function Industry() {
  const navigate = useNavigate();

  useEffect(() => {
    scrollElement("industry");
  }, []);

  return (
    <main id="industry">
      <header className="md:px-16 px-5 text-white primary-bg lg:h-[160vh] md:h-[230vh] h-[200vh]">
        <div className="flex items-center justify-center">
          <div className="py-32 text-center md:w-[60%]">
            <span className="text-sm text-appPrimary">Industries</span>
            <p className="md:text-5xl text-3xl font-extrabold mt-4">
              Transforming Industries <br /> with Breakthrough Technologies
            </p>
          </div>
        </div>
        <div className="flex lg:flex-row flex-col items-center py-16 ">
          <div className="lg:pe-24 lg:pb-0 pb-20">
            <p className="md:text-5xl text-3xl font-extrabold pb-10 md:leading-[4rem]">
              Driving New <br /> Opportunities with <br /> Cloud-Fueled
              Innovation
              <img
                src={Underline}
                alt="underlineImage"
                className="md:w-auto w-[10rem]"
              />
            </p>
            <p className="text-sm mb-10">
              As industry boundaries become disrupted, they create new value and
              opportunities for business. Neudesic helps organizations identify
              and capitalize on these new opportunities.
            </p>
            <Button
              className="bg-appPrimary hover:!bg-appPrimary border-none h-12"
              onClick={() => scrollElement("allIndustryCard")}
            >
              <div className="text-appSecondary flex items-center justify-center gap-3 text-base font-semibold">
                See Our All Industry <Arrow width="13" height="13" />
              </div>
            </Button>
          </div>
          <img
            src={IndustryImage}
            alt="industryRight"
            className="lg:w-[30rem] md:w-[30rem] w-[25rem]"
          />
        </div>
      </header>
      <div
        className="md:px-16 px-5 relative -top-[11rem] pt-[4.5rem]"
        id="allIndustryCard"
      >
        <AllIndustries />
      </div>
      <div className="md:px-16 px-5">
        <div className="primary-bg p-5 flex justify-center  rounded-xl  relative -bottom-32">
          <img
            src={Elipse}
            alt="elipseImage"
            className="absolute bottom-5 left-5 hidden md:block"
          />
          <div className="text-white py-16 text-center">
            <p className="md:text-5xl text-3xl font-extrabold">Get in Touch</p>
            <p className="text-sm my-8">
              Let’s talk about your upcoming initiatives and how we can help.
            </p>
            <Button
              className=" bg-appPrimary hover:!bg-appPrimary border-none h-12 md:w-auto w-full"
              onClick={() => navigate(routePaths.contact)}
            >
              <div className="text-appSecondary flex items-center justify-center gap-3 text-base font-semibold">
                Contact Us <Arrow width="13" height="13" />
              </div>
            </Button>
          </div>
          <img
            src={PostBox}
            alt="postBoxImage"
            className="absolute bottom-0 right-10 hidden md:block w-[10rem]"
          />
        </div>
      </div>
    </main>
  );
}

export default Industry;
