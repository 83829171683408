import React from "react";
import { bestServicesDetails } from "../../utills/Constants";

function ServiceSolution() {
  const borderRestriced = [7, 8, 9];

  return (
    <section className="text-center md:px-16 px-5 py-10">
      <div className="mb-20 lg:mt-0 mt-20">
        <span className="text-sm text-appSecondary">
          Our Flexibility Service
        </span>
        <p className="sm:text-5xl text-3xl font-extrabold">
          Our Best Service Solution
        </p>
      </div>

      <div className="md:grid grid-cols-3 grid-flow-row text-start border border-white">
        {bestServicesDetails.map((item, idx) => (
          <div
            key={`service-${idx}`}
            className={` ${!borderRestriced.includes(idx) && "border-b"}  ${
              (idx + 1) % 3 !== 0 && "border-r"
            }  p-5`}
          >
            <img src={item.picture} alt="servicesImage" />
            <p className="text-dark_grey_1 text-2xl font-bold mb-5">
              {item.title}
            </p>
            <p className="text-light_grey_2 text-base">{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ServiceSolution;
