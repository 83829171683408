interface IProps {
  fill?: string;
  width?: string;
  height?: string;
}

export const Arrow = ({ fill, width, height }: IProps) => {
  return (
    <svg
      width={width ? width : "15"}
      height={height ? height : "15"}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8338 3.34518L1.75639 14.4226L0.577881 13.2441L11.6553 2.16666H5.33381V0.5H14.5005V9.66666H12.8338V3.34518Z"
        fill={fill ? fill : "#24204D"}
      />
    </svg>
  );
};
