export function InstagramLogo() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9461 8.76785C20.936 8.01047 20.7942 7.26062 20.5271 6.55185C20.2954 5.954 19.9416 5.41105 19.4883 4.95768C19.0349 4.50431 18.4919 4.1505 17.8941 3.91885C17.1944 3.6562 16.4553 3.51419 15.7081 3.49885C14.7461 3.45585 14.4411 3.44385 11.9991 3.44385C9.55709 3.44385 9.24409 3.44385 8.28909 3.49885C7.54225 3.5143 6.80344 3.65632 6.10409 3.91885C5.50615 4.15034 4.96311 4.50409 4.50973 4.95748C4.05634 5.41087 3.70258 5.95391 3.47109 6.55185C3.20792 7.25098 3.06622 7.98996 3.05209 8.73685C3.00909 9.69985 2.99609 10.0048 2.99609 12.4468C2.99609 14.8888 2.99609 15.2008 3.05209 16.1568C3.06709 16.9048 3.20809 17.6428 3.47109 18.3438C3.70297 18.9416 4.05699 19.4844 4.51053 19.9376C4.96407 20.3908 5.50716 20.7444 6.10509 20.9758C6.80253 21.249 7.54147 21.4012 8.29009 21.4258C9.25309 21.4688 9.55809 21.4818 12.0001 21.4818C14.4421 21.4818 14.7551 21.4818 15.7101 21.4258C16.4572 21.4111 17.1965 21.2694 17.8961 21.0068C18.4938 20.7749 19.0366 20.421 19.4899 19.9677C19.9433 19.5144 20.2972 18.9715 20.5291 18.3738C20.7921 17.6738 20.9331 16.9358 20.9481 16.1868C20.9911 15.2248 21.0041 14.9198 21.0041 12.4768C21.0021 10.0348 21.0021 9.72485 20.9461 8.76785ZM11.9931 17.0648C9.43909 17.0648 7.37009 14.9958 7.37009 12.4418C7.37009 9.88785 9.43909 7.81885 11.9931 7.81885C13.2192 7.81885 14.3951 8.30591 15.262 9.17289C16.129 10.0399 16.6161 11.2158 16.6161 12.4418C16.6161 13.6679 16.129 14.8438 15.262 15.7108C14.3951 16.5778 13.2192 17.0648 11.9931 17.0648ZM16.8001 8.72585C16.2031 8.72585 15.7221 8.24385 15.7221 7.64785C15.7221 7.50635 15.75 7.36623 15.8041 7.23551C15.8583 7.10478 15.9376 6.986 16.0377 6.88594C16.1377 6.78588 16.2565 6.70652 16.3873 6.65237C16.518 6.59822 16.6581 6.57035 16.7996 6.57035C16.9411 6.57035 17.0812 6.59822 17.2119 6.65237C17.3427 6.70652 17.4614 6.78588 17.5615 6.88594C17.6616 6.986 17.7409 7.10478 17.7951 7.23551C17.8492 7.36623 17.8771 7.50635 17.8771 7.64785C17.8771 8.24385 17.3951 8.72585 16.8001 8.72585Z"
        fill="white"
      />
      <path
        d="M11.9932 15.445C13.6517 15.445 14.9962 14.1005 14.9962 12.442C14.9962 10.7835 13.6517 9.43896 11.9932 9.43896C10.3347 9.43896 8.99023 10.7835 8.99023 12.442C8.99023 14.1005 10.3347 15.445 11.9932 15.445Z"
        fill="white"
      />
    </svg>
  );
}
