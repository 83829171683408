import React, { useState } from "react";
import LeftArrow from "../../../assests/svg/arrowLeft.svg";
import RightArrow from "../../../assests/svg/arrowRight.svg";

function CustomTabs({ tabData }: any) {
  const initialTabIndex = 0;
  const [curTab, setCurTab] = useState(initialTabIndex);

  const handleRightClick = () => {
    if (curTab + 1 <= tabData.length - 1) {
      setCurTab(curTab + 1);
    } else {
      setCurTab(initialTabIndex);
    }
  };

  const handleLeftClick = () => {
    if (curTab - 1 < 0) {
      setCurTab(tabData.length - 1);
    } else {
      setCurTab(curTab - 1);
    }
  };

  return (
    <main className="flex flex-col items-center justify-center w-full">
      <section className="min-h-[10rem] text-light_grey_2 font-medium mb-10">
        {tabData[curTab].description}
      </section>
      {tabData && tabData.length > 1 && (
        <footer className="flex gap-5">
          <img src={LeftArrow} alt="leftArrow" onClick={handleLeftClick} />
          <img src={RightArrow} alt="rightArrow" onClick={handleRightClick} />
        </footer>
      )}
    </main>
  );
}

export default CustomTabs;
