import { Button } from "antd";
import { Arrow } from "../../assests/svg/Arrow";
import OfficeImage from "../../assests/svg/officeImage.svg";
import { officeAddressDetails, routePaths } from "../../utills/Constants";
import { useNavigate } from "react-router-dom";

function Offices() {
  const navigate = useNavigate();
  return (
    <section className="md:px-16 px-5 py-40">
      <div className="sm:flex justify-between items-end mb-10">
        <div>
          <p className="text-5xl text-dark_grey_1 font-extrabold mb-4">
            Our offices
          </p>
          <span className="text-sm text-light_grey_2">
            Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
            phasellus mollis sit aliquam sit <br />
            nullam neque ultrices.
          </span>
        </div>
        <Button
          className="mt-5 bg-appPrimary hover:!bg-appPrimary border-none h-12 w-full sm:w-auto sm:block hidden"
          onClick={() => navigate(routePaths.career)}
        >
          <div className="text-appSecondary flex items-center justify-center gap-3 text-base font-semibold">
            Join Our Team <Arrow width="13" height="13" />
          </div>
        </Button>
      </div>
      <div className="md:bg-light_grey_1 md:p-16 rounded-3xl flex lg:flex-row flex-col-reverse items-center">
        <img
          src={OfficeImage}
          alt="officeImage"
          className="lg:w-[60%] w-full"
        />
        <div className="lg:ps-16 lg:w-[40%] grid grid-rows-2 grid-flow-col gap-10 lg:mb-0 mb-10 w-full">
          {officeAddressDetails.map((item, idx) => (
            <div
              className="p-8 bg-white rounded-xl drop-shadow-xl md:w-auto w-full"
              key={`addressCard_${idx}`}
            >
              <p className="text-2xl font-bold mb-4">{item.cityName}</p>
              <span className="text-light_grey_2 text-sm">{item.address}</span>
              <p className="text-light_grey_2 mt-4 font-bold">{item.contact}</p>
            </div>
          ))}
        </div>
      </div>
      <Button
        className="mt-10 bg-appPrimary hover:!bg-appPrimary border-none h-12 w-full sm:hidden block"
        onClick={() => navigate(routePaths.career)}
      >
        <div className="text-appSecondary flex items-center justify-center gap-3 text-base font-semibold">
          Join Our Team <Arrow width="13" height="13" />
        </div>
      </Button>
    </section>
  );
}

export default Offices;
