import React from "react";
import Layout from "../components/common/layout/Layout";
import About from "../pages/about/About";

function AboutContainer() {
  return (
    <Layout footerExtendedHeight={true}>
      <About />
    </Layout>
  );
}

export default AboutContainer;
