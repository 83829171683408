export function LinkedinLogo() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.98292 7.65993C6.19132 7.65993 7.17092 6.68033 7.17092 5.47194C7.17092 4.26354 6.19132 3.28394 4.98292 3.28394C3.77452 3.28394 2.79492 4.26354 2.79492 5.47194C2.79492 6.68033 3.77452 7.65993 4.98292 7.65993Z"
        fill="white"
      />
      <path
        d="M9.23673 9.31793V21.4569H13.0057V15.4539C13.0057 13.8699 13.3037 12.3359 15.2677 12.3359C17.2047 12.3359 17.2287 14.1469 17.2287 15.5539V21.4579H20.9997V14.8009C20.9997 11.5309 20.2957 9.01794 16.4737 9.01794C14.6387 9.01794 13.4087 10.0249 12.9057 10.9779H12.8547V9.31793H9.23673ZM3.09473 9.31793H6.86973V21.4569H3.09473V9.31793Z"
        fill="white"
      />
    </svg>
  );
}
