import { Button, Input } from "antd";
import BackgroundImage from "../../assests/svg/bgCircle.svg";
import Stripe from "../../assests/svg/stripe2.svg";
import HalfCircle from "../../assests/svg/elipse.svg";
import TextArea from "antd/es/input/TextArea";
import { Arrow } from "../../assests/svg/Arrow";
import Offices from "../../components/about/Offices";
import { ourWebsites } from "../../utills/Constants";
import { useEffect, useState } from "react";
import { FacebookLogo } from "../../assests/svg/FacebookLogo";
import { InstagramLogo } from "../../assests/svg/InstagramLogo";
import { YoutubeLogo } from "../../assests/svg/YoutubeLogo";
import { TwitterLogo } from "../../assests/svg/TwitterLogo";
import { LinkedinLogo } from "../../assests/svg/LinkedinLogo";

function Contact() {
  const initialState = {
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialState);

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    setFormData(initialState);
  };

  const handlefieldChange = (event: any) => {
    const { name, value } = event.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (event: any) => {
    const { value } = event.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value) || value === "" || value === "-") {
      setFormData((prev) => ({ ...prev, phone: value }));
    }
  };

  useEffect(() => {
    const startingPosition: any = document.getElementById("contact");
    startingPosition.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <main id="contact">
      <header className="primary-bg h-screen text-white flex flex-col items-center pt-48">
        <div className="z-40 text-center p-2">
          <span className="text-appPrimary text-sm">Contact Us</span>
          <p className="md:text-5xl text-3xl font-extrabold py-5">
            Reach Out To Us
          </p>
          <p className="text-sm ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        <img src={BackgroundImage} alt="bgImage" className="absolute top-36" />
      </header>
      <div className="relative">
        <section className="md:px-16 px-5 sm:flex absolute sm:-top-[15rem] -top-[13rem] w-full">
          <div className="primary-bg lg:w-[30%] sm:w-[35%]  sm:rounded-tl-lg sm:rounded-bl-lg sm:rounded-t-none rounded-t-lg  sm:customBoxShadow relative">
            <img
              src={Stripe}
              alt="stripeImage"
              className="md:h-auto sm:h-[15rem] h-[12rem]"
            />
            <img
              src={HalfCircle}
              alt="circleImage"
              className="md:h-[10rem] absolute right-0 top-20 sm:h-[7rem] hidden sm:block"
            />
            <div className="flex flex-col items-center sm:justify-normal justify-center sm:mt-36 sm:pb-0 pb-20">
              <p className="text-2xl text-appPrimary font-bold mb-3">
                Follow Us
              </p>
              <div className="flex items-center lg:gap-6 gap-3 customHover">
                <FacebookLogo />
                <InstagramLogo />
                <YoutubeLogo />
                <TwitterLogo />
                <LinkedinLogo />
              </div>
            </div>
          </div>
          <form
            className="lg:w-[70%] sm:w-[65%] bg-white sm:rounded-tr-lg sm:rounded-br-lg rounded-b-lg sm:rounded-b-none lg:p-14 md:p-10 p-5 py-10 customBoxShadow"
            onSubmit={handleFormSubmit}
          >
            <div className=" grid sm:grid-cols-2 grid-flow-row gap-8 mb-8">
              <div>
                <div className="mb-2 font-semibold">Name</div>
                <Input
                  size="large"
                  className="h-[3rem] rounded-md"
                  placeholder="Enter name here..."
                  name="name"
                  value={formData.name}
                  onChange={handlefieldChange}
                  required
                />
              </div>
              <div>
                <div className="mb-2 font-semibold">Email</div>
                <Input
                  size="large"
                  className="h-[3rem] rounded-md"
                  placeholder="Enter your email here..."
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handlefieldChange}
                  required
                />
              </div>
            </div>
            <div className="grid sm:grid-cols-2 grid-flow-row gap-8 mb-8">
              <div>
                <div className="mb-2 font-semibold">Phone</div>
                <Input
                  size="large"
                  className="h-[3rem] rounded-md"
                  placeholder="Enter phone here..."
                  name="phone"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  maxLength={13}
                  required
                />
              </div>
              <div>
                <div className="mb-2 font-semibold">Company Name</div>
                <Input
                  size="large"
                  className="h-[3rem] rounded-md"
                  placeholder="Enter company name here..."
                  name="company"
                  value={formData.company}
                  onChange={handlefieldChange}
                  required
                />
              </div>
            </div>
            <div className="mb-2 font-semibold">Leave us a message</div>
            <TextArea
              placeholder="Please type your message here..."
              autoSize={{ minRows: 5, maxRows: 5 }}
              className="mb-10"
              name="message"
              value={formData.message}
              onChange={handlefieldChange}
              required
            />
            <Button
              className="sm:w-auto w-full bg-appPrimary hover:!bg-appPrimary border-none h-14 rounded-none rounded-tr-lg rounded-bl-lg "
              htmlType="submit"
            >
              <div className="text-appSecondary text-base px-4 font-semibold flex items-center gap-3 justify-center">
                Send Message <Arrow width="13" height="13" />
              </div>
            </Button>
          </form>
        </section>
        <div className="lg:h-[27rem] sm:h-[24rem] h-[60rem]"></div>
        <div className="grid sm:grid-cols-3 grid-flow-row gap-8 md:px-16 px-5">
          {ourWebsites.map((item, idx) => (
            <div
              key={`sites_${idx}`}
              className="bg-white drop-shadow-2xl flex items-center md:p-8 sm:p-4 p-6 rounded-2xl"
            >
              <img src={item.picture} alt="coverImage" />
              <div className="md:ps-8 sm:ps-4 ps-8">
                <p className="md:text-2xl sm:text-base font-bold mb-3 text-2xl">
                  {item.title}
                </p>
                <span className="md:text-sm sm:text-xs text-light_grey_2 text-sm">
                  {item.url}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Offices />
    </main>
  );
}

export default Contact;
