import CardImageOne from "../assests/svg/CSOne.svg";
import CardImageTwo from "../assests/svg/CSTwo.svg";
import ProfileImage from "../assests/svg/customerProfileTwo.svg";
import BlogImageOne from "../assests/svg/blogOne.svg";
import BlogImageTwo from "../assests/svg/blogTwo.svg";
import BlogImageThree from "../assests/svg/blogThree.svg";
import ServiceOne from "../assests/svg/service_1.svg";
import ServiceTwo from "../assests/svg/service_2.svg";
import ServiceThree from "../assests/svg/service_3.svg";
import ServiceFour from "../assests/svg/service_4.svg";
import ServiceFive from "../assests/svg/service_5.svg";
import ServiceSix from "../assests/svg/service_6.svg";
import ServiceSeven from "../assests/svg/service_7.svg";
import ServiceEight from "../assests/svg/service_8.svg";
import ServiceNine from "../assests/svg/service_9.svg";
import ElipseOne from "../assests/svg/elipse_1.svg";
import ElipseTwo from "../assests/svg/elipse_2.svg";
import ElipseThree from "../assests/svg/elipse_3.svg";
import ElipseFour from "../assests/svg/elipse_4.svg";
import FinancialImage from "../assests/svg/financial.svg";
import Bulb from "../assests/svg/bulb.svg";
import Star from "../assests/svg/star.svg";
import Sun from "../assests/svg/sun.svg";
import Settings from "../assests/svg/setting.svg";
import Key from "../assests/svg/key.svg";
import Heart from "../assests/svg/heart.svg";
import Eye from "../assests/svg/eye.svg";
import Shield from "../assests/svg/shield.svg";
import Team from "../assests/svg/group.svg";
import Medel from "../assests/svg/medel.svg";
import SuitCase from "../assests/svg/suitCase.svg";
import User from "../assests/svg/user.svg";
import SettingsTwo from "../assests/svg/setting_2.svg";

interface IOpenPosition {
  [key: string]: any[];
}

export const routePaths = {
  home: "/",
  service: "/service",
  industry: "/industry",
  about: "/about",
  career: "/career",
  contact: "/contact",
};

export const services = [
  "Cloud Infrastructure Services",
  "Data & Artificial Intelligence",
  "Application Innovation",
  "Modern Work",
  "Business Transformation & Strategy",
  "Hyperautomation",
  "Security Services",
  "Business Applications",
  "Integration and APIs",
  "Solution Centers",
];

export const quickLinks = [
  "Industries",
  "Insights & Blogs",
  "About",
  "Contact Us",
  "Careers",
  "Case Studies",
];

export const offeredServiceDetails = [
  {
    title: "Cloud Infrastructure Services",
    description: "Cloud Migration & Transformation Services",
  },
  {
    title: "Data & Artificial Intelligence",
    description:
      "Connecting, Organizing & Utilizing Data Assets for Enterprise Analytics",
  },
  {
    title: "Application Innovation",
    description: "Breathe New Life into Legacy Systems",
  },
  {
    title: "Modern Work",
    description:
      "Transform your Hybrid Workforce to Increase Productivity and Deliver Business Results",
  },
  {
    title: "Business Transformation & Strategy",
    description: "Achieve Transformative Outcomes with Strategic Technology.",
  },
  {
    title: "Hyperautomation",
    description:
      "Accelerate Business Driven Automation for Operational Excellence",
  },
];

export const tabData = [
  {
    description:
      "'Engaging in collaboration with the Consultant has been incredibly inspiring. Their team consistently goes above and beyond, offering exceptional service and achieving impressive results. Whether it's conducting market research or implementing marketing campaigns, they have demonstrated a deep understanding of our industry and business.'",
  },
  { description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
];

export const caseStudies = [
  {
    id: 1,
    title: `10 Web Design Blogs You Can't Miss`,
    chip: ["Marketing"],
    name: "Gianna Johnson",
    role: "Author",
    coverImage: CardImageOne,
    profile: ProfileImage,
  },
  {
    id: 2,
    title: `Why We Love Web (And You Should, Too!)`,
    chip: ["Design"],
    name: "Gianna Johnson",
    role: "Author",
    coverImage: CardImageTwo,
    profile: ProfileImage,
  },
];

export const blogDetails = [
  {
    id: 1,
    blogType: "Insights",
    title: "10 Great Examples of Responsive Websites",
    author: "Joanna Fawn",
    coverImage: BlogImageOne,
  },
  {
    id: 2,
    blogType: "News",
    title: "5 Web Design Blogs You Should Be Reading",
    author: "John Doe",
    coverImage: BlogImageTwo,
  },
  {
    id: 3,
    blogType: "Insights",
    title: "What Will Website Be Like In 100 Years?",
    author: "Joanna Fawn",
    coverImage: BlogImageThree,
  },
];

export const bestServicesDetails = [
  {
    title: "Cloud Infrastructure Services",
    description: "Cloud Migration & Transformation Services",
    picture: ServiceOne,
  },
  {
    title: "Modernize, Use and Govern Enterprise Analytics and AI",
    description:
      "Connecting, Organizing & Utilizing Data Assets for Enterprise Analytics",
    picture: ServiceTwo,
  },
  {
    title: "Application Innovation",
    description: "Breathe New Life into Legacy Systems",
    picture: ServiceThree,
  },
  {
    title: "Business Transformation & Strategy",
    description: "Achieve Transformative Outcomes with Strategic Technology",
    picture: ServiceFour,
  },
  {
    title: "Hyperautomation",
    description:
      "Accelerate Business Driven Automation for Operational Excellence",
    picture: ServiceFive,
  },
  {
    title: "Security Services",
    description:
      "Mitigate Risk to Accelerate Innovation, Transformation, and Growth",
    picture: ServiceSix,
  },
  {
    title: "Business Applications",
    description: "Thinking + Designing = Engaging",
    picture: ServiceSeven,
  },
  {
    title: "Integration and APIs",
    description:
      "Delivering More Reliable, Agile, and Cost Effective Integration Solutions",
    picture: ServiceEight,
  },
  {
    title: "Solution Center",
    description:
      "Maximizing Value with Cost-Effective Onshore Service and Leading-Edge Technology",
    picture: ServiceNine,
  },
];

export const whyChooseDetails = [
  { title: "Build a functional prototype in 24 hrs", picture: ElipseOne },
  { title: "Create an MVP within two weeks", picture: ElipseTwo },
  { title: "Kickstart software development", picture: ElipseThree },
  { title: "Get 24/7 tech & business support", picture: ElipseFour },
];

export const allIndustryDetails = [
  {
    title: "Financial Services",
    subTopic:
      "We understand the complex regulatory environment affecting the financial services industry.",
    description:
      "That's why leading banking, capital markets, and insurance organizations choose Neudesic to deliver cloud-fueled innovation that meets the needs of their customers, while ensuring full regulatory compliance. See how Neudesic can accelerate your enterprise innovation and time to value while mitigating unnecessary risk.",
    coverPhoto: FinancialImage,
  },
  {
    title: "Food & Beverage",
    subTopic: "",
    description: "",
    coverPhoto: "",
  },
  {
    title: "Healthcare",
    subTopic: "",
    description: "",
    coverPhoto: "",
  },
  {
    title: "Hospitality & Gaming",
    subTopic: "",
    description: "",
    coverPhoto: "",
  },
  {
    title: "Manufacturing",
    subTopic: "",
    description: "",
    coverPhoto: "",
  },
  {
    title: "Media & Entertainment",
    subTopic: "",
    description: "",
    coverPhoto: "",
  },
  {
    title: "Non-profit",
    subTopic: "",
    description: "",
    coverPhoto: "",
  },
  {
    title: "Oil & Gas",
    subTopic: "",
    description: "",
    coverPhoto: "",
  },
  {
    title: "Retail",
    subTopic: "",
    description: "",
    coverPhoto: "",
  },
  {
    title: "Transportation & Logistics",
    subTopic: "",
    description: "",
    coverPhoto: "",
  },
  {
    title: "Power & Utilities",
    subTopic: "",
    description: "",
    coverPhoto: "",
  },
];

export const whySassGenie = [
  "Leverage the Cloud to reduce costs and increase flexibility",
  "Process and analyze Big Data faster to improve decision making",
  "Expand market reach with enterprise-ready mobile solutions",
  "Integrate systems and applications to enhance productivity",
  "Monetize APIs and other digital assets to boost revenue",
];

export const ourValuesDetails = [
  {
    title: "Innovation",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam sit nullam neque ultrices.",
    picture: Bulb,
  },
  {
    title: "Growth",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam sit nullam neque ultrices.",
    picture: Star,
  },
  {
    title: "Ownership",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam sit nullam neque ultrices.",
    picture: Sun,
  },
  {
    title: "Teamwork",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam sit nullam neque ultrices.",
    picture: Settings,
  },
  {
    title: "Accessibility",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam sit nullam neque ultrices.",
    picture: Key,
  },
  {
    title: "Passion",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit phasellus mollis sit aliquam sit nullam neque ultrices.",
    picture: Heart,
  },
];

export const officeAddressDetails = [
  {
    cityName: "New York",
    address: "7674 Gartner Avenue. ST Corona, New York, NY, 11368",
    contact: "(415) 931-1633",
  },
  {
    cityName: "Los Angeles",
    address: "680 47th St. Los Angeles, California, CA, 90011",
    contact: "(323) 232-5311",
  },
];

export const whyApply = [
  {
    title: "Early stage startup",
    description:
      "Join our team of extraordinary people to build awesome products and transform ideas into beautiful websites.",
  },
  {
    title: "High level of autonomy",
    description:
      "We believe our team is our best asset, so we like to give everyone a high degree of responsibility and independence in how they’re structuring their work.",
  },
  {
    title: "Extremely promising product",
    description:
      "No one else does exactly what we do, and if we get it right, it has the potential to become a reference worldwide.",
  },
  {
    title: "And the team of course",
    description:
      "Join a fun, optimistic and driven team who loves growing together and gets quite competitive when it comes to boardgames.",
  },
];

export const objectives = [
  {
    title: "Transparency",
    description: "on every single step we make.",
    picture: Eye,
  },
  {
    title: "Trust",
    description: "and work of the highest quality.",
    picture: Shield,
  },
  {
    title: "Openness",
    description: "in relationships with all of our partners.",
    picture: Team,
  },
  {
    title: "Experts",
    description: "in all things Webflow and design.",
    picture: Medel,
  },
];

export const openPositions: IOpenPosition = {
  design: ["Senior - Staff Product Designer", "Head of Product Design"],
  engineering: [
    "Senior - Staff Mobile Engineer (iOS)",
    "Senior - Staff Fullstack Engineer",
    "Senior - Staff Product Engineer",
    "Senior - Staff Mobile Engineer (Android)",
    "Senior - Staff Backend Engineer",
  ],
};

export const ourWebsites = [
  { title: "Sales & business", url: "sales@example.com", picture: SuitCase },
  { title: "Partner program", url: "partner@example.com", picture: User },
  { title: "Help & support", url: "help@example.com", picture: SettingsTwo },
];
