import React from "react";
import { Arrow } from "../../assests/svg/Arrow";
import CircleImage from "../../assests/svg/circles.svg";
import { blogDetails, routePaths } from "../../utills/Constants";
import CustomCard from "../common/card/CustomCard";
import Elipse from "../../assests/svg/halfElipse.svg";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

function Blogs() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="primary-bg md:p-24 px-5 py-10 text-white flex md:flex-row flex-col-reverse items-center">
        <div className="md:text-start text-center md:mt-0 mt-16">
          <span className="text-appPrimary text-sm ">Partner</span>
          <p className="md:text-5xl text-4xl font-extrabold mt-3">
            Who we partner with
          </p>
          <p className="text-sm md:mt-4 mt-16 md:w-[60%]">
            Saas Genie has assembled a fully-managed partner ecosystem of
            leading cloud and independent software partners that provide us
            maximum flexibility to architect solutions using the best tools for
            the job. This allows your technology deployments to match the needs,
            skillsets, and strategies of your organization.
          </p>
          <p className="flex items-center md:justify-normal justify-center gap-3 text-sm mt-4 text-appPrimary">
            Learn More <Arrow fill="#FCD463" width="13" height="13" />
          </p>
        </div>
        <img
          src={CircleImage}
          alt="circleImage"
          className=" md:w-[21rem] w-[12rem]"
        />
      </div>
      <div className="py-24 h-[70rem]">
        <span className="text-sm text-appSecondary md:px-16 px-5">Blog</span>
        <div className="flex items-center justify-between mt-3 md:px-16 px-5">
          <p className="md:text-5xl text-4xl font-extrabold">
            News and insights
          </p>
          <p className="text-sm text-dark_grey_1 md:flex items-center gap-3 hidden">
            View All Blogs <Arrow width="12" height="12" />
          </p>
        </div>
        <div className="grid grid-rows-1 grid-flow-col gap-8 mt-16 overflow-x-scroll no-scrollbar md:px-16 px-5">
          {blogDetails.map((detaills, idx) => (
            <CustomCard
              data={detaills}
              isSecondaryCardDesign={true}
              key={`blog_${idx}`}
            />
          ))}
        </div>
        <div className="primary-bg md:p-16 p-5 text-center text-white relative top-[10rem] rounded-md md:mx-16 mx-5">
          <div className="md:hidden flex justify-center">
            <img src={Elipse} alt="halfcircle" className="w-[5rem] mb-5" />
          </div>
          <span className="text-sm text-appPrimary">Careers</span>
          <p className="md:text-5xl text-4xl font-bold  mt-2">Our People</p>
          <p className="mt-4 md:text-xl">
            Be inspired. Find your fit. Make a difference.
          </p>
          <Button
            className="mt-5 bg-appPrimary hover:!bg-appPrimary border-none h-12"
            onClick={() => navigate(routePaths.career)}
          >
            <div className="text-appSecondary flex items-center gap-3 text-base font-semibold">
              Show Open Positions <Arrow width="13" height="13" />
            </div>
          </Button>
          <img src={Elipse} alt="halfcircle" className="md:block hidden" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Blogs;
