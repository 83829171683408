import React from "react";
import Logo from "../../../assests/svg/logo.svg";
import { quickLinks, services } from "../../../utills/Constants";
import { FacebookLogo } from "../../../assests/svg/FacebookLogo";
import { InstagramLogo } from "../../../assests/svg/InstagramLogo";
import { YoutubeLogo } from "../../../assests/svg/YoutubeLogo";
import { TwitterLogo } from "../../../assests/svg/TwitterLogo";
import { LinkedinLogo } from "../../../assests/svg/LinkedinLogo";

interface IProps {
  extendedHeight?: boolean;
}

function AppFooter({ extendedHeight }: IProps) {
  return (
    <footer className="md:p-14 p-5 bg-appFooter text-white">
      <div className={`${extendedHeight && `min-h-[15rem]`}`}></div>
      <div className="md:grid grid-rows-1 grid-flow-col gap-4">
        <div className="md:mb-0 mb-10">
          <img src={Logo} alt="logo" className="mb-5" />
          <span>
            Lorem ipsum dolor sit amet consectetur adipiscing <br /> elit am
            dolor sit.
          </span>
          <div className="flex items-center lg:gap-6 gap-3 customHover mt-8">
            <FacebookLogo />
            <InstagramLogo />
            <YoutubeLogo />
            <TwitterLogo />
            <LinkedinLogo />
          </div>
        </div>
        <div className="md:mb-0 mb-10">
          <p className="text-xl text-appPrimary font-semibold mb-3">Services</p>
          {services.map((item, idx) => {
            return (
              <div className="p-2 ps-0" key={`service_${idx}`}>
                {item}
              </div>
            );
          })}
        </div>
        <div className="md:mb-0 mb-10">
          <p className="text-xl text-appPrimary font-semibold mb-3">
            Quick Links
          </p>
          {quickLinks.map((item, idx) => {
            return (
              <div className="p-2 ps-0" key={`link_${idx}`}>
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </footer>
  );
}

export default AppFooter;
