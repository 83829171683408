import React from "react";
import TopNavBar from "../nav/TopNavBar";
import AppFooter from "../footer/AppFooter";

interface IProps {
  children: React.ReactNode;
  footerExtendedHeight?: boolean;
}

function Layout({ children, footerExtendedHeight }: IProps) {
  return (
    <main className="w-full">
      <div>
        <TopNavBar />
        {children}
        <AppFooter extendedHeight={footerExtendedHeight} />
      </div>
    </main>
  );
}

export default Layout;
