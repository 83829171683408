export function FacebookLogo() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1512 22.0999H13.6672V13.3399H16.6072L17.0512 9.9319H13.6672V7.7479C13.6672 6.7639 13.9432 6.0919 15.3592 6.0919H17.1712V3.0319C16.8592 2.9959 15.7912 2.8999 14.5432 2.8999C11.9272 2.8999 10.1512 4.4959 10.1512 7.4119V9.9319H7.19922V13.3399H10.1512V22.0999Z"
        fill="white"
      />
    </svg>
  );
}
