import { useEffect } from "react";
import TargetImage from "../../assests/svg/targetIllustration.svg";
import Women from "../../assests/svg/AIWomen.svg";
import Blocks from "../../assests/svg/blocks.svg";
import BackgroundImage from "../../assests/svg/bgCircle.svg";
import ServiceSolution from "../../components/service/ServiceSolution";
import { Button } from "antd";
import { Arrow } from "../../assests/svg/Arrow";
import { routePaths, whyChooseDetails } from "../../utills/Constants";
import { scrollElement } from "../../utills/utill";
import { useNavigate } from "react-router-dom";

function Service() {
  const navigate = useNavigate();

  useEffect(() => {
    scrollElement("service");
  }, []);

  return (
    <main id="service">
      <div className="primary-bg md:px-16 px-5 flex flex-col items-center lg:h[90vh] md:h-screen sm:h-[110vh] h-[100vh] pt-[5rem] relative">
        <section className="text-white text-center lg:w-[45%] md:w-[70%] mt-24 z-40">
          <span className="text-appPrimary text-sm ">Services</span>
          <p className=" sm:text-5xl text-3xl font-extrabold mt-3 mb-5">
            Innovative IT Solutions for a Connected Future
          </p>
          <p className="text-xs">
            Empower your business with cutting-edge IT services designed for
            efficiency and growth. From seamless tech integration to
            cybersecurity, our experts are dedicated to optimizing your digital
            landscape. Explore a world of possibilities where technology meets
            transformation, and your success is our priority.
          </p>
        </section>
        <img
          src={TargetImage}
          alt="targetImage"
          className="absolute md:left-0 lg:-bottom-[6.98rem] sm:-bottom-[5.4rem] lg:w-[23rem] md:w-[18rem] sm:w-[18rem] -bottom-[5.5rem] sm:px-0 px-5"
        />
        <img src={BackgroundImage} alt="bgImage" className="absolute top-30" />
      </div>
      <ServiceSolution />
      <section className="md:px-16 px-5 primary-bg flex md:flex-row flex-col md:py-0 py-10 items-center justify-between text-white">
        <div className="lg:pe-48 py-5 md:text-start text-center">
          <p className="md:text-5xl text-3xl font-extrabold md:leading-[4rem] mb-5">
            Ready To Get Started Of Strategy Business.
          </p>
          <span className="text-sm">
            Softewer being able to crank out videos consistently, localize this
            them for different regions, and still save resources, time, has been
            a game change
          </span>
          <div className="my-10 flex items-center md:justify-normal justify-center gap-5">
            <img src={Blocks} alt="blocksImage" />
            <div>
              <p className="md:text-2xl text-lg font-extrabold">
                Manage Your Software
              </p>
              <span className="text-sm">App Design, Website</span>
            </div>
          </div>
          <Button
            className="h-[3.4rem] bg-appPrimary hover:!bg-appPrimary border-none text-base font-semibold md:w-auto w-full"
            onClick={() => navigate(routePaths.contact)}
          >
            <div className="text-appSecondary flex items-center justify-center gap-3 text-base font-semibold">
              Get Started Now <Arrow width="13" height="13" />
            </div>
          </Button>
        </div>
        <img src={Women} alt="womenImage" className="customFilter me-20" />
      </section>
      <section className="md:px-16 px-5 py-24 text-center">
        <p className="md:text-5xl text-3xl font-extrabold mb-6">
          Why Choose Us?
        </p>
        <span className="text-sm text-light_grey_2">
          We personalize how we work to fit your project needs. Our approach
          helps augment innovation.
        </span>
        <div className="py-14 md:grid grid-rows-1 grid-flow-col">
          {whyChooseDetails.map((item, idx) => (
            <div
              key={`choose_${idx}`}
              className="flex flex-col items-center md:p-8 md:py-0 py-10 md:border-r border-b"
            >
              <img src={item.picture} alt="elipseImage" className=" mb-5" />
              <p className="text-lg text-dark_grey_1 font-extrabold">
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
}

export default Service;
