import React from "react";

import Layout from "../components/common/layout/Layout";
import Home from "../pages/home/Home";

function HomeContainer() {
  return (
    <Layout footerExtendedHeight={true}>
      <Home />
    </Layout>
  );
}

export default HomeContainer;
