import { Button } from "antd";
import { Arrow } from "../../assests/svg/Arrow";
import BackgroundWeb from "../../assests/svg/web.svg";
import { objectives, openPositions, whyApply } from "../../utills/Constants";
import { useEffect } from "react";
import { scrollElement } from "../../utills/utill";

function Career() {
  useEffect(() => {
    const startingPosition: any = document.getElementById("career");
    startingPosition.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <main id="career">
      <header className="primary-bg p-5 text-white flex flex-col items-center text-center pb-32 pt-[5rem]">
        <div className="z-50">
          <div className="text-sm text-appPrimary mt-36">Careers</div>
          <p className="sm:text-5xl text-3xl font-extrabold py-5">
            Start doing work that matters
          </p>
          <p className="text-sm pb-5">
            Join our team of extraordinary people to build awesome products and
            transform ideas into beautiful websites.
          </p>
          <Button
            className="mt-5 bg-appPrimary hover:!bg-appPrimary border-none h-12 w-full md:w-auto rounded-none rounded-tr-lg rounded-bl-lg"
            onClick={() => scrollElement("openPositions")}
          >
            <div className="text-appSecondary text-base font-semibold">
              Find Your Fit
            </div>
          </Button>
        </div>
        <img src={BackgroundWeb} alt="bgImage" className="absolute" />
        <div className="h-[15rem]"></div>
        <p className="sm:text-5xl text-3xl font-extrabold py-8">
          Why you should apply
        </p>
        <div className="text-start py-20">
          {whyApply.map((item, idx) => (
            <div key={`list_${idx}`} className="sm:flex mb-5">
              <div className="me-5 lg:w-[20rem] md:w-[15rem] sm:w-[12rem] text-appPrimary">
                {item.title}
              </div>
              <ul className="lg:w-[30rem] md:w-[25rem] sm:w-[20rem] list-disc sm:ms-0 ms-8 sm:mt-0 mt-2">
                <li>{item.description}</li>
              </ul>
            </div>
          ))}
        </div>
      </header>
      <section className="md:px-16 px-5">
        <div className="grid sm:grid-cols-4 grid-cols-1 grid-flow-row gap-4 relative -top-[5.5rem]">
          {objectives.map((item, idx) => (
            <div
              key={`objective_${idx}`}
              className="lg:p-6 sm:p-3 p-6 rounded-lg customBoxShadow bg-white text-start sm:w-auto w-full"
            >
              <img src={item.picture} alt="illustrationImage" />
              <p className="lg:text-2xl text-xl font-bold text-appSecondary pt-4 pb-2">
                {item.title}
              </p>
              <span className="md:text-sm  text-light_grey_2">
                {item.description}
              </span>
            </div>
          ))}
        </div>
        <div className="mb-32 pt-[4.5rem]" id="openPositions">
          <p className="sm:text-5xl text-3xl font-extrabold text-center mb-16">
            Open positions
          </p>
          <div className="lg:px-44 md:px-20 sm:px-16 px-5">
            {Object.keys(openPositions).map((sector, idx) => (
              <div key={`sector_${idx}`}>
                <div className="text-light_grey_3 text-sm capitalize font-semibold">
                  {sector}
                </div>
                {openPositions[sector].map((position, idx) => (
                  <div
                    key={`position_${idx}`}
                    className={`sm:flex items-center justify-between py-3 ${
                      idx !== openPositions[sector].length - 1
                        ? "border-b"
                        : "mb-5"
                    }`}
                  >
                    <p className="text-lg font-bold text-dark_grey_1">
                      {position}
                    </p>
                    <span className="text-sm text-appSecondary flex items-center gap-2 sm:mt-0 mt-2">
                      Apply <Arrow width="10" height="10" />
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <p className="text-light_grey_2 text-sm text-center">
            Yo're not looking for specific position right now, but we're always
            <br />
            open to spontaneous applications.
            <span className="underline underline-offset-2 font-semibold text-appSecondary">
              Just drop us a line!
            </span>
          </p>
        </div>
      </section>
    </main>
  );
}

export default Career;
