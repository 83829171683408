import { Button } from "antd";
import React, { useEffect } from "react";
import Arrow from "../../assests/svg/arrow.svg";
import KidImage from "../../assests/svg/VRKid.svg";
import OfferedService from "../../components/home/OfferedService";
import CaseStudies from "../../components/home/CaseStudies";
import Blogs from "../../components/home/Blogs";
import { routePaths } from "../../utills/Constants";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const handleNavigate = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    const startingPosition: any = document.getElementById("home");
    console.log(startingPosition, "startingPosition");

    startingPosition.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  return (
    <main id="home">
      <section className="sm:px-16 p-7 sm:flex primary-bg text-white min-h-[70rem] pt-[5rem]">
        <article className="sm:w-[40%] w-full py-32">
          <div className="sm:text-5xl text-4xl sm:text-start text-center font-bold tracking-wide sm:leading-[4.5rem]">
            Ready To Level Up <br /> Your Online <br /> Business?
          </div>
          <p className="text-sm pt-4 pb-8 sm:text-start text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <Button
            className="bg-appPrimary hover:!bg-appPrimary border-none h-12 sm:w-auto w-full "
            value="large"
          >
            <div
              className="flex text-appSecondary font-medium justify-center"
              onClick={() => handleNavigate(routePaths.service)}
            >
              See Our Services <img className="ms-2" src={Arrow} alt="arrow" />
            </div>
          </Button>
        </article>
        <aside className="sm:w-[60%] w-full">
          <img src={KidImage} alt="kidImage" className="float-end" />
        </aside>
      </section>
      <OfferedService />
      <CaseStudies />
      <Blogs />
    </main>
  );
}

export default Home;
