export function YoutubeLogo() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.8392 6.6223C22.1799 6.97346 22.4218 7.4083 22.5406 7.88289C22.8578 9.64159 23.0118 11.4258 23.0006 13.2129C23.0069 14.9732 22.8529 16.7305 22.5406 18.4629C22.4218 18.9375 22.1799 19.3723 21.8392 19.7235C21.4986 20.0746 21.0713 20.3297 20.6006 20.4629C18.8806 20.9229 12.0006 20.9229 12.0006 20.9229C12.0006 20.9229 5.12057 20.9229 3.40057 20.4629C2.93939 20.3367 2.51855 20.0937 2.17872 19.7574C1.83888 19.421 1.59153 19.0027 1.46057 18.5429C1.14334 16.7842 0.989352 14.9999 1.00057 13.2129C0.991809 11.4392 1.14579 9.66845 1.46057 7.92289C1.57936 7.4483 1.82129 7.01346 2.16192 6.6623C2.50255 6.31113 2.92982 6.05608 3.40057 5.92289C5.12057 5.46289 12.0006 5.46289 12.0006 5.46289C12.0006 5.46289 18.8806 5.46289 20.6006 5.88289C21.0713 6.01608 21.4986 6.27113 21.8392 6.6223ZM15.501 13.2129L9.75098 16.4829V9.94287L15.501 13.2129Z"
        fill="white"
      />
    </svg>
  );
}
