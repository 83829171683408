import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routePaths } from "./utills/Constants";
import HomeContainer from "./container/HomeContainer";
import ServiceContainer from "./container/ServiceContainer";
import IndustryContainer from "./container/IndustryContainer";
import AboutContainer from "./container/AboutContainer";
import CareerContainer from "./container/CareerContainer";
import ContactContainer from "./container/ContactContainer";

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path={routePaths.home} element={<HomeContainer />} />
          <Route path={routePaths.service} element={<ServiceContainer />} />
          <Route path={routePaths.industry} element={<IndustryContainer />} />
          <Route path={routePaths.about} element={<AboutContainer />} />
          <Route path={routePaths.career} element={<CareerContainer />} />
          <Route path={routePaths.contact} element={<ContactContainer />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
