import React from "react";
import Layout from "../components/common/layout/Layout";
import Contact from "../pages/contact/Contact";

function ContactContainer() {
  return (
    <Layout>
      <Contact />
    </Layout>
  );
}

export default ContactContainer;
