import React from "react";
import Layout from "../components/common/layout/Layout";
import Service from "../pages/service/Service";

function ServiceContainer() {
  return (
    <Layout>
      <Service />
    </Layout>
  );
}

export default ServiceContainer;
