import React from "react";

interface IProps {
  data: any;
  isSecondaryCardDesign?: boolean;
}

function CustomCard({ data, isSecondaryCardDesign }: IProps) {
  return (
    <main
      className={`rounded-xl ${
        !isSecondaryCardDesign && "drop-shadow-2xl"
      } min-w-[21rem]`}
    >
      <div className={`w-full ${isSecondaryCardDesign && "h-[18rem]"}`}>
        <img
          src={data.coverImage}
          alt="coverImage"
          className="w-full h-full object-cover rounded-t-xl"
        />
      </div>

      <div
        className={`bg-white p-5 rounded-b-xl ${
          isSecondaryCardDesign && "border-l-2 mt-5"
        }`}
      >
        {isSecondaryCardDesign && (
          <p className="text-sm mb-5 text-light_grey_2">{data.blogType}</p>
        )}
        <p className="text-2xl font-bold text-dark_grey_1 mb-4">{data.title}</p>
        {data.chip &&
          data.chip.length &&
          data.chip.map((item: any, idx: number) => (
            <div
              className="bg-appPrimary inline-block py-2 px-4 text-sm text-white rounded-3xl mb-10"
              key={`chip_${idx}`}
            >
              {item}
            </div>
          ))}
        {data.profile && (
          <div className="flex items-center gap-4">
            <img src={data.profile} alt="profileImage" />
            <div>
              <p className="text-sm text-light_grey_2 font-bold">{data.name}</p>
              <p className="text-light_grey_3">{data.role}</p>
            </div>
          </div>
        )}
        {isSecondaryCardDesign && (
          <div className="mt-12">Author — {data.author}</div>
        )}
      </div>
    </main>
  );
}

export default CustomCard;
